import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { RainbowButton } from "../../../../elements/rainbowbutton";
import grampusClient from "../../../../../module/api/grampus";
import { useAccount, useReadContracts, useWriteContract, useTransactionReceipt } from "wagmi";
import { abi as ocsContractAbi } from "../../../../../assets/contract/JuicePackNFT.js";
import { toast } from "react-toastify";
import { Loading } from "../../../../../components/elements/loading/Loading";
import { LinkButton } from "../../../../../components/elements/linkbutton";

import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { sleep, isTest } from "../../../../../utility/helpers";
import Modal from "../../../../elements/modal/Modal";
import Player from "../../../../elements/player/Player";

export const Minting = () => {
  const [isBlockEnabled, setIsBlockEnabled] = useState(false);

  useEffect(() => {
    grampusClient.isOcsBlockEnabled().then((res) => {
      setIsBlockEnabled(res.data.enabled);
    });
  }, []);

  const [txLoading, setTxLoading] = useState(false);
  const [txHash, setTxHash] = useState(null);
  let txHashResult = useTransactionReceipt({
    hash: txHash,
  });
  /*
  {
    rarity: 0,
    tokenId: 1,
  }
  */
  const [mintingResult, setMintingResult] = useState(null);

  const nftDataList = [
    {
      rarity: 0,
      name: "Grape Juice Pack",
      url: "https://juicepack.nft.grampus.co/images/GrapeJuicePack.png",
    },
    {
      rarity: 1,
      name: "Apple Juice Pack",
      url: "https://juicepack.nft.grampus.co/images/AppleJuicePack.png",
    },
    {
      rarity: 2,
      name: "Mango Juice Pack",
      url: "https://juicepack.nft.grampus.co/images/MangoJuicePack.png",
    },
    {
      rarity: 3,
      name: "Carrot Juice Pack",
      url: "https://juicepack.nft.grampus.co/images/CarrotJuicePack.png",
    },
  ];

  const [fruitsCardList, setFruitsCardList] = useState([
    {
      key: 1,
      src_gray: require("../../../../../assets/images/homepage/ocs_minting_fruit_gray_1.png"),
      src_normal: require("../../../../../assets/images/homepage/ocs_minting_fruit_1.png"),
      selected: false,
    },
    {
      key: 2,
      src_gray: require("../../../../../assets/images/homepage/ocs_minting_fruit_gray_2.png"),
      src_normal: require("../../../../../assets/images/homepage/ocs_minting_fruit_2.png"),
      selected: false,
    },
    {
      key: 3,
      src_gray: require("../../../../../assets/images/homepage/ocs_minting_fruit_gray_3.png"),
      src_normal: require("../../../../../assets/images/homepage/ocs_minting_fruit_3.png"),
      selected: false,
    },
    {
      key: 4,
      src_gray: require("../../../../../assets/images/homepage/ocs_minting_fruit_gray_4.png"),
      src_normal: require("../../../../../assets/images/homepage/ocs_minting_fruit_4.png"),
      selected: false,
    },
    {
      key: 5,
      src_gray: require("../../../../../assets/images/homepage/ocs_minting_fruit_gray_5.png"),
      src_normal: require("../../../../../assets/images/homepage/ocs_minting_fruit_5.png"),
      selected: false,
    },
  ]);

  const account = useAccount();
  const { openConnectModal } = useConnectModal();
  const [ocsContractAddress, setOcsContractAddress] = useState(null);
  const { writeContractAsync } = useWriteContract();
  const [selectOrder, setSelectOrder] = useState([]);

  let readContractResult = useReadContracts({
    contracts: [
      {
        address: ocsContractAddress,
        abi: ocsContractAbi,
        functionName: "mintingCountMap",
        args: [account.address],
      },
    ],
  });

  const runMinting = async () => {
    if (!account.address || !isMaxSelected || txLoading) {
      return;
    }
    setTxLoading(true);

    await readContractResult.refetch();

    if (readContractResult.data && Number(readContractResult.data[0].result) >= 1) {
      toast.warn("You can mint only once");
      setTxLoading(false);
      return;
    }

    if (readContractResult.isLoading) {
      toast.loading("Checking minting count...");

      // await until isLoading is false
      for (let i = 0; i < 30; i++) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        if (!readContractResult.isLoading) {
          break;
        }
      }

      if (readContractResult.isLoading) {
        toast.dismiss();
        toast.error("Failed to check minting count");
        setTxLoading(false);
        return;
      } else {
        toast.dismiss();
      }
    }

    if (readContractResult.error) {
      toast.error("Failed to check minting count : " + readContractResult.error);
      setTxLoading(false);
      return;
    }

    const mintingData = await grampusClient.getMintingData(account.address, selectOrder);
    if (!mintingData || mintingData.status != 200) {
      toast.warn(mintingData?.msg || "Failed to get minting data");
      setTxLoading(false);
      return;
    }

    let mintingSuccess = false;
    try {
      const hash = await writeContractAsync({
        abi: ocsContractAbi,
        address: ocsContractAddress,
        functionName: "mintJuicyPack",
        args: [mintingData.data.tokenId, mintingData.data.rarity, mintingData.data.signature],
      });
      setTxHash(hash);

      for (let i = 0; i < 30; i++) {
        await sleep(1000);
        txHashResult = await txHashResult.refetch();
        if (txHashResult.data && txHashResult.data.status === "success") {
          mintingSuccess = true;
          break;
        } else if (txHashResult.data && txHashResult.data.status === "fail") {
          toast.error("Failed to mint juice pack");
          console.log(txHashResult);
          break;
        }
      }
    } catch (e) {
      console.error(e);
      setTxLoading(false);
      const messages = e.message.match(/Error: (.*)\n/);
      if (messages && messages.length > 1) {
        toast.error("Occur error in tx : " + messages[1].trim());
      } else {
        throw e;
      }
      return;
    }

    if (mintingSuccess) {
      await readContractResult.refetch();
      setMintingResult(mintingData.data);
    }

    setTxLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();

    // read data from file
    grampusClient.getContract("ocs").then((res) => {
      setOcsContractAddress(res.data.address);
    });
  }, []);

  const onCardSelect = (key) => {
    const fruitsCard = fruitsCardList.find((fruitsCard) => fruitsCard.key === key);
    if (!fruitsCard || txLoading) return;

    if (!fruitsCard.selected && isMaxSelected) {
      return;
    }

    fruitsCard.selected = !fruitsCard.selected;

    if (fruitsCard.selected) {
      selectOrder.push(fruitsCard.key);
    } else {
      const index = selectOrder.indexOf(fruitsCard.key);
      if (index > -1) {
        selectOrder.splice(index, 1);
      }
    }

    setSelectOrder([...selectOrder]);

    setFruitsCardList([...fruitsCardList]);
  };

  const isMaxSelected = fruitsCardList.filter((fruitsCard) => fruitsCard.selected).length >= 3;

  if (!isBlockEnabled) {
    return <></>;
  }

  return (
    <section className={styles.section}>
      <div className={styles.bg_box}>
        <Player className={styles.bg_video} src="https://code4chain.s3.ap-northeast-2.amazonaws.com/5_grampus/juicy_main.mp4" />
        <img className={styles.bg_img1} src={require("../../../../../assets/images/homepage/ocs_minting_character1.png")} alt="bg" />
        <img className={styles.bg_img2} src={require("../../../../../assets/images/homepage/ocs_minting_character2.png")} alt="bg" />
        <img className={styles.bg_img3} src={require("../../../../../assets/images/homepage/ocs_minting_character3.png")} alt="bg" />
        <img className={styles.bg_img4} src={require("../../../../../assets/images/homepage/ocs_minting_character4.png")} alt="bg" />
        <img className={styles.bg_img5} src={require("../../../../../assets/images/homepage/ocs_minting_character5.png")} alt="bg" />
      </div>

      <LinkButton className={styles.banner_box} link="https://wallet.coinbase.com/ocs">
        <div className={styles.banner_slide_box}>
          <img className={styles.banner_img} src={require("../../../../../assets/images/homepage/ocs_banner.png")} alt="banner" />
          <img className={styles.banner_img} src={require("../../../../../assets/images/homepage/ocs_banner.png")} alt="banner" />
          <img className={styles.banner_img} src={require("../../../../../assets/images/homepage/ocs_banner.png")} alt="banner" />
        </div>
      </LinkButton>
      <div className={cn("container", styles.container)}>
        <div className={styles.connect_box}>
          <div className={styles.title_box}>Onchain Summer 2024</div>
          <div className={styles.contents_box}>
            Mint your FREE Juice Pack NFT to <br className="pc" /> win exclusive rewards!
          </div>
          <div className={styles.exp_box}>
            <div className={styles.exp_title}>
              <img className={styles.exp_icon} src={require("../../../../../assets/images/homepage/jelly_icon.png")} alt="exp" />
              Connect your Wallet (1 mint per account)
            </div>
            <div className={styles.exp_title}>
              <img className={styles.exp_icon} src={require("../../../../../assets/images/homepage/jelly_icon.png")} alt="exp" />
              Select your 3 favorite fruit
            </div>
            <div className={styles.exp_title}>
              <img className={styles.exp_icon} src={require("../../../../../assets/images/homepage/jelly_icon.png")} alt="exp" />
              Click the 'Mint Now' button to mint your Juice Pack
            </div>
            {/* <div className={styles.exp_title}>
              <img className={styles.exp_icon} src={require("../../../../../assets/images/homepage/jelly_icon.png")} alt="exp" />
              Wait patiently for your airdrop rewards! <br className="pc" /> Rewards are based on the type and tier of your Juice Pack
            </div> */}
          </div>
          <div className={styles.connect_button_box}>
            <RainbowButton className={styles.connect_button} />
            <LinkButton className={styles.more_button} link="https://gram-voyage.medium.com/dive-into-onchain-summer-with-juicy-adventure-juice-pack-nft-mint-on-base-e4a7aafacc56">
              Learn More
            </LinkButton>
          </div>
        </div>
        <div className={styles.fruits_select_box}>
          <div className={styles.select_title_box}>Select your 3 favorite fruit</div>
          <div className={styles.select_box}>
            {fruitsCardList.map((fruitsCard) => (
              <div key={fruitsCard.key} className={cn(styles.select_card, { [styles.disabled]: !account.address || (!fruitsCard.selected && isMaxSelected) }, { [styles.selected]: fruitsCard.selected && account.address })} onClick={() => onCardSelect(fruitsCard.key)}>
                <img className={cn(styles.select_img)} src={fruitsCard.src_gray} alt="fruits_gray" />
                <img className={cn(styles.select_img, styles.select_img_normal, { [styles.selected]: fruitsCard.selected && account.address }, { [styles.hidden]: (!fruitsCard.selected && isMaxSelected) || !account.address })} src={fruitsCard.src_normal} alt="fruits_normal" />
              </div>
            ))}
          </div>
          <div className={styles.mint_box}>
            <button className={cn(styles.mint_button, { [styles.disabled]: !account.address || !isMaxSelected || txLoading })} onClick={runMinting}>
              {txLoading ? <Loading className={styles.loading} /> : <>Mint Now</>}
            </button>
          </div>
        </div>
      </div>
      <Modal outerClassName={styles.modal_outer} visible={mintingResult} onClose={() => setMintingResult(null)} unquenchable={true}>
        <div className={styles.modal_box}>
          <Player className={styles.modal_bg_video} loop={false} src={require("../../../../../assets/videos/homepage/juicy_popup.mp4")} />
          <div className={styles.modal_title_box}>Congratulations!</div>
          <div className={styles.nft_info_box}>
            {mintingResult && (
              <>
                You got {nftDataList[mintingResult?.rarity].name} #{mintingResult?.tokenId}
              </>
            )}
          </div>
          <div className={styles.nft_image_box}>
            <img className={styles.nft_image} src={mintingResult ? nftDataList[mintingResult?.rarity].url : ""} alt="nft" />
          </div>

          <div className={styles.close_button_box}>
            <button className={styles.close_button} onClick={() => setMintingResult(null)}>
              OK
            </button>
            <LinkButton className={styles.detail_button} link="https://gram-voyage.medium.com/dive-into-onchain-summer-with-juicy-adventure-juice-pack-nft-mint-on-base-e4a7aafacc56">
              Details
            </LinkButton>
          </div>
        </div>
      </Modal>
    </section>
  );
};
